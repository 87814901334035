<template>
	<Toast></Toast>
	<div class="middle" :style="backgroundDiv">
		<div style='background-color: rgba(255, 255, 255, 0.6);margin: 30px;'
			class="surface-card p-6 shadow-2 border-round w-full lg:w-4">
			<div class="text-center mb-5">
				<img src="images/blocks/logos/hyper.svg" alt="Image" height="50" class="mb-3">
				<div class="text-900 text-3xl font-medium mb-3">PDFJS-Annotation Library</div>
				<div class="text-900 text-3xl font-medium mb-3">Auth Apply System</div>
				<span class="text-600 font-medium line-height-3">Contact</span>
				<a class="font-medium no-underline ml-2 text-blue-500 cursor-pointer"
					title='Email:990527197@qq.com'>Contact Me</a>
			</div>

			<div>
				<div v-if='!has_cookie'>
					<label for="email1" class="block text-900 font-medium mb-2">Email</label>
					<InputText id="email1" type="text" v-model='email' class="w-full mb-3" />
					
					<label for="password1" class="block text-900 font-medium mb-2">Verycode</label>
					<div class="p-inputgroup">
						<InputText type="text" :disabled='!show' v-model='vecode' v-on:keyup.enter="login" id='vericode'
							style="width: 160px;" placeholder="验证码" />
						<Button v-show='show' @click='getVerifyCode' label="获取"></Button>
						<Button class="p-button-secondary" v-show='!show' disabled :label="vecode_time+' S'"></Button>
					</div>
					<!-- 				<div class="flex align-items-center justify-content-between mb-6">
						<div class="flex align-items-center">
							<Checkbox id="rememberme1" :binary="true" v-model="checked" class="mr-2"></Checkbox>
							<label for="rememberme1">自动登录</label>
						</div>
						<a class="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer"
							title='请联系990527197@qq.com'>忘记密码?</a>
					</div> -->
					<Button style="margin-top: 1.25rem;" @click="login" label="立即登录" icon="pi pi-user"
						class="w-full"></Button>
				</div>
				
				<div v-if='has_cookie'>
					<Button @click='checkLogin' class="w-full" :disabled='processing' :label="'登录当前账号:'+old_email"></Button>
				</div>
				<div style="display: flex;flex-direction: row;justify-content: space-between;margin-top: 10px;">
					<div class="p-field-checkbox" style='margin-top: 10px;' title='勾选之后此设备将可在两周免验证登录,请勿在非本人设备使用!'>
						<Checkbox style='border-radius: 50%;' id="checkOption1" name="option" value="此设备自动登录" @change="changeAutoLogin" v-model="checkboxValue" />
						<label for="checkOption1" style="color: #666666;margin-left: 5px;">允许此设备自动登录</label>
					</div>
					<Button label="切换账号" class="p-button-secondary p-button-text"
						@click='changeUser' />
			</div>
			</div>
		</div>
	</div>
</template>

<script>
	import NetWorkService from '../service/NetWorkService.js';
	export default {
		data() {
			return {
				checked: true,
				password: '',
				has_cookie: false,
				backgroundDiv: {
					// backgroundImage: 'url(https://fireflycos.libertynlp.com/kg-static/kg-backimg.jpg)',
					backgroundImage: 'url(https://fireflycos.libertynlp.com/kg-static/qiuye-2.jpg)',
				},
				checkboxValue: [],
				vecode_time: 0,
				visibleFull: true,
				show: true,
				count: '',
				email: 'weichaoxu1998@gmail.com',
				vecode: '',
				timer: null,
				old_email:null,
			}
		},
		created() {
			this.networkService = new NetWorkService();
		},
		mounted() {
			// this.readUserInfo();
			// this.login();
			this.checkCookie();
		},
		methods: {
			changeAutoLogin(){
				this.$appState.auto_login=this.checkboxValue.length;
				// console.log('当前登录',this.checkboxValue);
			},
			//切换账号
			changeUser() {
				this.has_cookie = false;
				this.$appState.auto_login = 0;
			},
			readUserInfo() {
				if (localStorage.getItem('kg_user_info') !== null) {
					var kg_user_info = JSON.parse(localStorage.getItem("kg_user_info"));
					this.email = kg_user_info.email;
					this.password = kg_user_info.password;
				}
			},
			//检查验证码
			checkCookie() {
				// //获取url中的uid
				// console.log(uid);
				if (localStorage.getItem('Autologin') != 0) {
					this.$appState.auto_login = localStorage.getItem('Autologin');
				}
				// console.log('auto_login',this.$appState.auto_login);
				if (this.$appState.auto_login == 0) {
					this.changeUser();
					return;
				}
				// console.log('长度',localStorage.getItem('UserInfo'));
				// localStorage.setItem('UserInfo',null);
				if (localStorage.getItem('UserInfo') != null && localStorage.getItem('UserInfo').split('#').length == 2) {
					this.has_cookie = true;
					this.$appState.auto_login = 1;
					this.checkboxValue='此设备自动登录';
					this.old_email=localStorage.getItem('UserInfo').split('#')[0];
				} else {
					this.changeUser();
				}
			},
			//自动登录检验登录态
			checkLogin() {
				//cookie存在之后再检查登录态
				var data = new URLSearchParams();
				var url = '/member/check-login-token';
				this.userLogin(url, data, 1);
			},
			//倒计时
			setTimer() {
				let holdTime = 19,
					_this = this;
				_this.vecode_time = "19"
				_this.Timer = setInterval(() => {
					if (holdTime <= 0) {
						_this.vecode_time = "20"
						clearInterval(_this.Timer);
						_this.show = true;
						return;
					}
					_this.vecode_time = holdTime;
					holdTime--;
				}, 1000)
			},
			//用验证码和邮箱登录
			login() {
				var vecode =this.vecode;
				console.log(vecode);
				if (vecode.length != 6 || vecode.replace(/[^0-9]/ig, "").length != 6) {
					this.showEmainError('请输入六位数字验证码');
					return;
				}
				//再次验证邮箱是否正确
				this.tryEmailLogin(this.email, vecode);
			},
			async tryEmailLogin(user_email, veri_code) {
				this.processing = true;
				this.$appState.processing = true;
				var data = new URLSearchParams();
				data.append("user_email", user_email);
				data.append("veri_code", veri_code);
				var url = '/member/email-login';
				// console.log("请求开始");
				var resp = await this.networkService.postService(url, data);
				if (resp.code == 200) {
					//关掉登录页面
					localStorage.setItem('Authorization', resp.data.cookie);
					localStorage.setItem('Autologin', this.$appState.auto_login);
					if (this.$appState.auto_login == 1) {
						localStorage.setItem('UserInfo', resp.data.cookie);
					} else {
						localStorage.setItem('UserInfo', null);
					}

					this.$appState.is_login = true;
					this.$emit('flushLoginStatus');
					this.$toast.add({
						severity: 'success',
						summary: '登录成功',
						detail: 'Enjoy pdfjs annotation api!',
						life: 1000
					});
					var _this=this;
					setTimeout(() => {
						//uni.showToast({title: '验证码已发送',icon:"none"});
						_this.$router.push({
							path: '/authkey',
						})
					}, 1000)
				} else {
					this.show = true;
					this.showEmainError(resp.msg);
				}
				this.processing = false;
				this.$appState.processing = false;
				
			},
			//用户微信登录
			async userLogin(url,data,login_type){
				this.processing = true;
				this.$appState.processing = true;
				var resp = await this.networkService.postService(url, data);
				if (resp.code == 200) {
					//关掉登录页面
					localStorage.setItem('Authorization', resp.data.cookie);
					localStorage.setItem('Autologin', this.$appState.auto_login);
					if(this.$appState.auto_login==1){
						localStorage.setItem('UserInfo',resp.data.cookie);
					}else{
						localStorage.setItem('UserInfo', null);
					}

					this.$appState.is_login=true;
					this.$toast.add({
						severity: 'success',
						summary: '登录成功',
						detail: 'Enjoy pdfjs annotation!',
						life: 1000
					});
					var _this=this;
					setTimeout(() => {
						//uni.showToast({title: '验证码已发送',icon:"none"});
						_this.$router.push({
							path: '/authkey',
						})
					}, 1000)
				} else {
					// this.showError(resp.msg);
					if(login_type==1){
						this.changeUser();
					}
				}
				this.processing = false;
				this.$appState.processing = false;
			},
			//邮箱错误提示
			showEmainError(msg) {
				this.$toast.add({
					severity: 'error',
					summary: '失败',
					detail: msg,
					life: 4000
				});
			},
			//从云端获取验证码
			getVerifyCode() {
				//打开计时器
				this.show = false;
				this.getVeriCode(this.email);
			},
			//获取验证码
			async getVeriCode(user_email) {
				this.$appState.processing = true;
				var data = new URLSearchParams();
				data.append("user_email", user_email);
				var url = '/member/get-vericode';
				console.log("请求开始");
				var resp = await this.networkService.postService(url, data);
				this.show = false;
				if (resp.code == 200) {
					this.$toast.add({
						severity: 'success',
						summary: '成功',
						detail: resp.msg,
						life: 3000
					})
					var _this = this;
					setTimeout(() => {
						//uni.showToast({title: '验证码已发送',icon:"none"});
						_this.setTimer();
					}, 5000)
				} else {
					this.show = true;
					this.showEmainError(resp.msg);
				}
				this.$appState.processing = false;
			},
		}

	}
</script>

<style scoped>
	.middle {
		height: 100%;
		position: fixed;
		width: 100%;
		background: no-repeat center top;
		background-size: cover;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.login-container {
		display: flex;
		width: 340px;
		flex-direction: column;
		padding: 20px;
		border-radius: 10px;
		/* background-color:#55585a+7a; */
		background-color: rgba(255, 255, 255, 0.4);
		justify-content: center;
	}

	.login-lable {
		color: #000000;
		opacity: 0.6;
		font-weight: 700;
	}

	.input-container {
		display: flex;
		flex-direction: column;
		margin-top: 20px;
	}
</style>
